import React from 'react'
import Apis from "../../services/Apis";
import { useSelector } from 'react-redux';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom'
import Cookies from 'universal-cookie';


const AuthAction = () => {
    const { getConfirmPayment, getPaymentMethodService, addCart, vendorCreation, orderCreation, saveCustomer, LoginService, checkEmail, verifyotpService, forgotPasswordApi, updatePasswordApi, getSubscriptionData} = Apis()
    const {formData} = useSelector(state => state.authdetail);
    const navigate = useNavigate();
    const location = useLocation();
    var cookies = new Cookies();
    const [searchParams] = useSearchParams();

    var q_param = searchParams.get('q');

    // const {getSubscription} = MasterAction()
    
    const getSubscription = () => async dispatch => {
      const response = await getSubscriptionData();
      if (response.status == 200) {
        if (response.data.status == 'success') {
          dispatch({
              type: 'setsubscriptionData',
              payload: response.data.data
          });
        } else {
          dispatch({
            type: 'setsubscriptionData',
            payload: []
          });
        }
      }
  };
    //create customer
    const createCustomer = (input_data, param) => async dispatch => {
        dispatch({
          type: 'setloader',
          payload: true
        });
        const response = await saveCustomer(input_data);
        
        const cusData = {
          email: formData.email,
          password: formData.password,
        }
        if(response.status == 200){
            const data = {
              username:formData.email,
              password: formData.password,
            }
            
            dispatch(userlogin(data, param))
            navigate(`/plan?q=${param}`);


            


        }
    
    };
    //login function
    const userlogin = (input_data, slug) => async dispatch => {
        dispatch({
          type: 'setloaderstatus',
          payload: true,
        });
        const response = await LoginService(input_data);
        if (response.data.status == "success") {
          if(response.data.login_type === "user"){
            // cookies.set('jwt', response.data.jwt_token)
            window.location.href =  `https://admin.shopersbay.com/welcomepage/?jwt=${response.data.jwt_token}&source=${slug}`;
          }else if(response.data.login_type === "customer"){
            cookies.set('jwt', response.data.jwt_token)
            
            // navigate(`/plan?q=${slug}`);



          }
          dispatch({
            type: 'setloaderstatus',
            payload: false,
          });
          dispatch({
            type: 'setfirstStepForm',
            payload: {
              email: "",
              password: "",
            },
          });
          
          

        } else {
          dispatch({
            type: 'setloginError',
            payload: response.data.msg,
           
          });
          dispatch({
            type: 'setloaderstatus',
            payload: false,
          });
        }
    };
    //email varification for sign up
    const varifyEmail = (input_data) => async dispatch => {
      dispatch(getSubscription())
      dispatch({
        type: 'setloaderstatus',
        payload: true,
      });
      const response = await checkEmail(input_data);
      if(response.data.status == 'error'){
        dispatch({
          type: 'setVerification_status',
          payload: response.data,
         
        });
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
      }else{
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
        dispatch({
          type: 'setcheckStatus',
          payload: 1,
         
        });
        dispatch({
          type: 'setVerification_status',
          payload: {},
         
        });
        localStorage.setItem('usid', input_data.email)
        localStorage.setItem('psid', input_data.password)
        dispatch({
          type: 'setformData',
          payload: {...formData, "id": response.data.data, "email": input_data.email, "password": input_data.password},
         
        });
      }
    };
    //otp varification
    const varifyOtppassword = (input_data, param) => async dispatch => {
      
      dispatch({
        type: 'setloaderstatus',
        payload: true,
      });
      const response = await verifyotpService(input_data);
      
      if (response.status == 200) {
          if (response.data.status == "success") {
            dispatch({
              type: 'setloaderstatus',
              payload: false,
            });
            dispatch({
                type: 'setotp_status',
                payload: true
            });

          dispatch({
              type: 'setotp_msz',
              payload: response.data.message
          });
          dispatch({
            type: 'setOtpStatus',
            payload: {},
           
          });
          if(location.pathname === '/forgot-password'){
            dispatch({
              type: 'setforgotStatus',
              payload: 2,
             
            });
          }else{
            var input_data1 = {
              email: formData.email,
              password: formData.password,
              name:"",
              category:2,
              gst:"",
              cin:"",
              address:"",
              state:"",
              pincode:""
            }

            dispatch(createCustomer(input_data1, param))
          }

          
          
          } else {
            dispatch({
              type: 'setloaderstatus',
              payload: false,
            });
            dispatch({
                type: 'setotp_msz',
                payload: response.data.message
            });
            dispatch({
                type: 'setotp_status',
                payload: false
            });
            dispatch({
              type: 'setOtpStatus',
              payload: response.data,
            
            });
          }
      }

    };
    //email varify for forgot password
    const forgotPassword = (input_data) => async dispatch => {
      dispatch({
        type: 'setloaderstatus',
        payload: true,
      });
      const response = await forgotPasswordApi(input_data);
      
      if(response.data.status == 'error'){
        
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
        dispatch({
          type: 'setVerification_status',
          payload: response.data,
         
        });
        dispatch({
          type: 'setloginError',
          payload: response.data.msg,
         
        });
      }else{
        dispatch({
          type: 'setloginError',
          payload: "",
         
        });
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
        dispatch({
          type: 'setforgotStatus',
          payload: 1,
        });
        dispatch({
          type: 'setVerification_status',
          payload: {},
         
        });
        dispatch({
          type: 'setformData',
          payload: {...formData, "id": response.data.data, "type": response.data.login_type, "email":input_data.email},
         
        });
      }
    };
    //password update
    const updatePassword = (input_data, data) => async dispatch => {
      dispatch({
        type: 'setloaderstatus',
        payload: true,
      });
      const response = await updatePasswordApi(input_data);
      if(response.data.status == 'error'){
        
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
      }else{
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
        dispatch({
          type: 'setforgotStatus',
          payload: 0,
        });
        if(data != ""){
          navigate(`/${data}`);
        }else{
          navigate(`/`);
        }
      }
    };
    //create vendor
    const vendorCreate = (input_data) => async dispatch => {
      const response = await vendorCreation(input_data);
      
      if(response.data.status == 'success'){
        window.location.href =  `https://admin.shopersbay.com/vendor/myaccount?jwt=${response.data.jwt_token}&source=${q_param}&profile=true`;
      }else{
      }
    };
    //create order
    const placeOrder = (input_data, data) => async dispatch => {
      const response = await orderCreation(input_data);
      if(response.data.status == 'error'){
        
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
      }else{
        if(response.data.payment_status == "pending"){
          window.location.href = response.data.data.redirect_url + `?callback=${window.location.origin+window.location.pathname}?q=${q_param}`;
        }
        
      }
    };
    //add to cart
    const addToCart = (input_data) => async dispatch => {
      const response = await addCart(input_data);
      if(response.data.status == 'success'){
        dispatch(paymentMethod())
      }
    };
    //get payment method
    const paymentMethod = () => async dispatch => {
      const response = await getPaymentMethodService()
      if(response.data.status == 'success'){
        const inputData = {
          "payment_method_key":  response.data.data[0].payment_method ,
          "channel": "Website",
          "payment_method_id":  response.data.data[0].id ,
          "customer_email":"",
          "shipping_address": {
            "addressId":"",
            "addresstype":"",
            "default":"",
            "address1":"",
            "address2":"",
            "city":"",
            "state":"",
            "pincode":"",
            "phone":"",
            "rstatus":"",
            "name":"",

          }
          
        }
        dispatch(placeOrder(inputData))

      }
    }
    //confirm payment
    const confirmPayment = (input_data) => async dispatch => {
      const response = await getConfirmPayment(input_data);
      if(response.data.status == 'success'){
        if(response.data.data.orderid != '' || response.data.payment_status === 'success'){
          var userData = {
            username: localStorage.getItem('usid'),
            password: localStorage.getItem('psid'),
            subscriptionid : localStorage.getItem('abcd')
          }
          dispatch(vendorCreate(userData))
        }
      }
    }

    
    
    


  return {
    userlogin:userlogin,
    varifyEmail:varifyEmail,
    createCustomer:createCustomer,
    varifyOtppassword:varifyOtppassword,
    forgotPassword:forgotPassword,
    updatePassword:updatePassword,
    placeOrder:placeOrder,
    vendorCreate:vendorCreate,
    addToCart:addToCart,
    paymentMethod:paymentMethod,
    confirmPayment:confirmPayment,
    getSubscription:getSubscription,
  }
}

export default AuthAction
